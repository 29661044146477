import React, { Component } from 'react';
import Recaptcha from 'react-recaptcha';
import axios from 'axios';
import {withRouter} from 'react-router-dom';
import PlacesAutocomplete, {geocodeByAddress} from 'react-places-autocomplete';

class Quote extends Component {
    constructor(props){
        super(props);
        this.state = {
            address: '',
            address_formatted:'',
            toAddress: '',
            toAddress_formatted: '',
            truckType: 'Vans',
            weight: 1,
            contact_name: '',
            contact_email: '',
            contact_phone: '',
            quoteAmount: 0,
            quoteAdjustment: 0,
            buttonName:'FUCK',
            isHuman: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        
    }

    handleSelect = address => {
        geocodeByAddress(address)
            .then(results => this.setState({address_formatted: results[0].formatted_address}))
            .catch(error => console.error('Error', error));
        
        this.setState({address});
        console.log(this.state.address);
        console.log(this.state.address_formatted);
      };
    
    handleSelectToAddr = toAddress => {
        geocodeByAddress(toAddress)
            .then(results => this.setState({toAddress_formatted: results[0].formatted_address}))
            .catch(error => console.error('Error', error));
        
        this.setState({toAddress});
        console.log(this.state.toAddress);
        console.log(this.state.toAddress_formatted);
      };

    handleChangeAddr = address => {
        this.setState({ address });
    };
    handleChangeToAddr = toAddress => {
        this.setState({ toAddress });
    };

    handleChange(event){
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event){
        event.preventDefault();
        if (this.state.buttonName == "quoteButton"){
            var self = this;

            //Disable button after first click to prevent multiple messages
            //this.refs.btn.setAttribute("disabled", "disabled");


            axios.post('https://api.truckquote.com/api/v1/quotes',{
                Equipment_Type:this.state.truckType,
                UserId:"ca41c9fc-34af-4e5c-a507-19b22f773072",
                Date:"12/10/2050",
                Weight:this.state.weight,
                Feet:53,
                IsWeb:true,
                SelectedServices:[],
                stops:[
                    {
                        Address: this.state.address_formatted,
                        city:"Texas",
                        state:"PA",
                        ZipCode:""
                    },
                    {
                        Address: this.state.toAddress_formatted,
                        city:"Texas",
                        state:"CA",
                        ZipCode:""
                    }
                ]
            })
            .then(res => {
                
                //this.setState({["quoteAmount"]: "$" + (res.data.result.quoteAmount + this.state.quoteAdjustment)});
                this.setState({["quoteAmount"]: Math.round((res.data.result.quoteAmount + this.state.quoteAdjustment)*100)/100});

                console.log(res);
                //window.location.reload(false);

                
                if (this.state.quoteAdjustment == 700){
                    this.state.truckType = 'HAZMAT';
                }else if(this.state.quoteAdjustment == -400){
                    this.state.truckType = 'Power Only';
                }
                axios.post('https://skitchoukov.pythonanywhere.com/kitchoukov/v1/email/send',{
                    to_email: "operations@akaal.io",
                    subject: "Quote requested from AKAAL.IO",
                    name: this.state.contact_name,
                    email: this.state.contact_email,
                    phone: this.state.contact_phone,
                    message: "Someone requested a quote: \n From Address: " + this.state.address_formatted + " \n To Address: " + this.state.toAddress_formatted + "\n Truck Type: "  + this.state.truckType + " \n Quote Amount: " + this.state.quoteAmount
                })
                .then(res => {
                    //alert("Your message has been sent. We will be in touch with you soon.");
                    //window.location.reload(false);
                });
            });
        }else{
            if(this.state.quoteAmount > 0){
                axios.post('https://skitchoukov.pythonanywhere.com/kitchoukov/v1/email/send',{
                    to_email: "operations@akaal.io",
                    subject: "Booked Truck from AKAAL.IO",
                    name: this.state.contact_name,
                    email: this.state.contact_email,
                    phone: this.state.contact_phone,
                    message: "Someone has booked a truck: \n From Address: " + this.state.address_formatted + " \n To Address: " + this.state.toAddress_formatted + "\n Truck Type: "  + this.state.truckType + " \n Quote Amount: " + this.state.quoteAmount
                })
                .then(res => {
                    alert("Your Booking has been sent. We will be in touch with you soon.");
                    window.location.reload(true);
                });
            }else{
                alert("Invalid Quote Amount.");
            }
        }
  
            
       
        
    }


    render() {
        return (
            <div className="wrap">
                <form onSubmit={this.handleSubmit}>
                    <div class="form-group">
                        <label className="contact_label">From Address:</label>
                        <PlacesAutocomplete searchOptions={{componentRestrictions: { country: ['us'] }}} value={this.state.address} onChange={this.handleChangeAddr} onSelect={this.handleSelect}>
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <input type="text" class="form-control" id="contact_address" tabIndex="1" placeholder="From Address *" name="contact_address" required
                                    {...getInputProps({
                                        placeholder: 'Search From Address *',
                                        className: 'location-search-input form-control',
                                    })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                        const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                        );
                                    })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                    <div class="form-group">
                        <label className="contact_label">To Address:</label>
                        <PlacesAutocomplete searchOptions={{componentRestrictions: { country: ['us'] }}} value={this.state.toAddress} onChange={this.handleChangeToAddr} onSelect={this.handleSelectToAddr}>
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <input type="text" class="form-control" id="contact_toAddress" tabIndex="1" placeholder="To Address *" name="contact_toAddress" required
                                    {...getInputProps({
                                        placeholder: 'Search To Address *',
                                        className: 'location-search-input form-control',
                                    })}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                        const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                            })}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                        );
                                    })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                    <div class="form-group">
                        <label className="contact_label">Truck Type:</label>
                        <select class="form-control" id="contact_truck_type" tabIndex="1" onChange={e => {
                                const selectedValue = e.target.value;
                                if(selectedValue === "Vans") {
                                    this.state.truckType = "Vans";
                                    this.state.quoteAdjustment = 200;
                                }else if (selectedValue === "Hazmat"){
                                    this.state.truckType = "Vans";
                                    this.state.quoteAdjustment = 1100;
                                }else if (selectedValue === "Power"){
                                    this.state.truckType = "Vans";
                                    this.state.quoteAdjustment = -400;
                                } else {
                                    this.state.truckType = "Reefers";
                                    this.state.quoteAdjustment = 300;
                                }
                            }} >
                            <option value="Vans">Dry Van</option>
                            <option value="Reefers">Reefer</option>
                            <option value="Hazmat">Hazmat</option>
                            <option value="Power">Power Only</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label className="contact_label">Weight (lbs):</label>
                        <input type="number" value={this.state.weight} onChange={this.handleChange} class="form-control" id="weight" tabIndex="1" placeholder="Weight *" name="weight" min="1" max="45000" required/>
                    </div>
                    <div class="form-group">
                        <label className="contact_label">Name:</label>
                        <input type="text" value={this.state.contact_name} onChange={this.handleChange} class="form-control" id="contact_name" tabIndex="2" placeholder="Your Name *" name="contact_name" required/>
                    </div>
                    <div class="form-group">
                        <label className="contact_label">Email:</label>
                        <input type="email" value={this.state.contact_email} onChange={this.handleChange} class="form-control" id="contact_email" tabIndex="3" placeholder="Your Email *" name="contact_email" required/>
                    </div>
                    <div class="form-group">
                        <label className="contact_label">Phone:</label>
                        <input type="text" value={this.state.contact_phone} onChange={this.handleChange} class="form-control" id="contact_phone" tabIndex="4" placeholder="Your Phone" name="contact_phone"/>
                    </div>

                    <button ref="btn"name = "quoteButton" type="submit" class="btn btn-primary contact_button" onClick={() => {this.state.buttonName = "quoteButton"}} tabIndex="5">Get Quote</button>
                    <br></br>
                    <br></br>
                    <label className="contact_label">Quote Amount: {"$" + this.state.quoteAmount}</label>

                 
                    <br></br>
                    <br></br>
                    <button ref="btn" name = "bookButton" type="submit" class="btn btn-primary contact_button" onClick={() => {this.state.buttonName = "bookButton"}} tabIndex="6" >Book Now</button>
                    <br></br>
                    <br></br>
                    
                </form>
                        
            </div>
        );
    }
}

export default withRouter(Quote);

