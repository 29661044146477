import React, { Component } from 'react';
import Recaptcha from 'react-recaptcha';
//import * as emailjs from 'emailjs-com';
import axios from 'axios';
import {withRouter} from 'react-router-dom';

class Contact extends Component {
    constructor(props){
        super(props);
        this.state = {
            contact_name: '',
            contact_email: '',
            contact_phone: '',
            contact_message: '',
            isHuman: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
        this.recaptchaVerified = this.recaptchaVerified.bind(this);
    }

    handleChange(event){
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event){
        if(this.state.isHuman){
            var self = this;

            //Disable button after first click to prevent multiple messages
            this.refs.btn.setAttribute("disabled", "disabled");

            event.preventDefault();
            
            axios.post('https://skitchoukov.pythonanywhere.com/kitchoukov/v1/email/send',{
                to_email: "info@akaal.io",
                subject: "New Message from AKAAL.IO",
                name: this.state.contact_name,
                email: this.state.contact_email,
                phone: this.state.contact_phone,
                message: this.state.contact_message
            })
            .then(res => {
                alert("Your message has been sent. We will be in touch with you soon.");
                window.location.reload(false);
            });
  
            
        }else{
            alert("Please verify that you are a human!");
            event.preventDefault();
        }
        
    }

    recaptchaLoaded(){
        console.log("Capcha successfully laoded");
    }

    recaptchaVerified(response){
        if(response){
            this.setState({
                isHuman: true
            })
        }
    }

    render() {
        return (
            <div className="wrap">
                <form onSubmit={this.handleSubmit}>
                    <div class="form-group">
                        <label className="contact_label">Name:</label>
                        <input type="text" value={this.state.contact_name} onChange={this.handleChange} class="form-control" id="contact_name" tabIndex="7" placeholder="Your Name *" name="contact_name" required/>
                    </div>
                    <div class="form-group">
                        <label className="contact_label">Email:</label>
                        <input type="email" value={this.state.contact_email} onChange={this.handleChange} class="form-control" id="contact_email" tabIndex="8" placeholder="Your Email *" name="contact_email" required/>
                    </div>
                    <div class="form-group">
                        <label className="contact_label">Phone:</label>
                        <input type="text" value={this.state.contact_phone} onChange={this.handleChange} class="form-control" id="contact_phone" tabIndex="9" placeholder="Your Phone" name="contact_phone"/>
                    </div>
                    <div className="contact_label" class="form-group">
                        <label className="contact_label">Description:</label>
                        <textarea type="text" value={this.state.contact_message} onChange={this.handleChange} class="form-control" id="contact_message" tabIndex="10" placeholder="Description *" name="contact_message" required/>
                    </div>

                    <Recaptcha
                        sitekey="6LfRvMYZAAAAAMAIdato63MhZyVYaSTYYjlNFjxc"
                        render="explicit"
                        onloadCallback={this.recaptchaLoaded}
                        verifyCallback={this.recaptchaVerified}
                    />
                    <br></br>
                    <button ref="btn" type="submit" class="btn btn-primary contact_button">Send</button>
                    <br></br>
                </form>
                        
            </div>
        );
    }
}

export default withRouter(Contact);

