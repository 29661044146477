import React, { Component } from 'react';

class Careers extends Component {
    render() {
        return (
            <div className="wrap">
                <div id="careers_section" className="jumbotron text-center">
                    {/*Title*/}
                    <div class="row align-items-center">
                        <div class="col-md-12">
                            <div id="careers_box" class="shadow-lg p-4 mb-4 bg-white">
                                <h1 id="white_background_text">Careers</h1>
                                <hr id="standard_line"></hr>

                                <div id="careers_description" class="row align-items-center">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-6">
                                        <h5 id="homepage_icon_text" >Hiring Owner Operators</h5>
                                        <ul className="default_text_color">
                                            <li>
                                                If you own a Semi truck, we have the freight for you
                                            </li>
                                            <li>
                                                    Tired of looking for your own freight? Let our Dispatch 
                                                    team do the work for you.
                                            </li>
                                            <li>
                                                Consistent & Steady Freight
                                            </li>
                                            <li>
                                                Competative Payouts
                                            </li>
                                        </ul>
                                
                                    </div>
                                    <div class="col-md-3"></div>
                                </div>

                            </div>
                        </div>
                        
                    </div>

                   

                    {/*Career Details*/}
                    
                </div>
            </div>
        );
    }
}

export default Careers;