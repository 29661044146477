import React, { Component } from 'react';
import Recaptcha from 'react-recaptcha';
import axios from 'axios';
import {withRouter} from 'react-router-dom';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
  } from 'react-places-autocomplete';



class Quote extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            address: '',
            toAddress: '',
            address_formatted:'',
            toAddress_formatted:''
        };
        this.handleSubmit = this.handleSubmit.bind(this);    
    }
     
      handleChange = address => {
        this.setState({ address });
      };
      handleChange2 = toAddress => {
        this.setState({ toAddress });
      };

    

     
      handleSelect = address => {
        /*
        geocodeByAddress(address)
          .then(results => getLatLng(results[0]))
          .then(latLng => console.log('Success', latLng))
          .catch(error => console.error('Error', error));
          */
        geocodeByAddress(address)
          .then(results => this.setState({address_formatted: results[0].formatted_address}))
          .catch(error => console.error('Error', error));
        
        this.setState({address});
        console.log(this.state.address);
        console.log(this.state.address_formatted);
      };

      handleSelect2 = toAddress => {
        /*
        geocodeByAddress(address)
          .then(results => getLatLng(results[0]))
          .then(latLng => console.log('Success', latLng))
          .catch(error => console.error('Error', error));
          */
        geocodeByAddress(toAddress)
          .then(results => this.setState({toAddress_formatted: results[0].formatted_address}))
          .catch(error => console.error('Error', error));
        
        this.setState({toAddress});
        console.log(this.state.toAddressd);
        console.log(this.state.toAddress_formatted);
      };

      handleSubmit(event){
        window.location.reload(false);
        this.refs.btn.setAttribute("disabled", "disabled");

        event.preventDefault();
        console.log("SENT");

      }
     
      render() {
        return (
            <div className="wrap">
                <form onSubmit={this.handleSubmit}>
                    <div>
                        <PlacesAutocomplete
                            value={this.state.address}
                            onChange={this.handleChange}
                            onSelect={this.handleSelect}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                <input name="address" id="address"
                                {...getInputProps({
                                    placeholder: 'Search Places ...',
                                    className: 'location-search-input',
                                })}
                                tabIndex="1"/>
                                <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                    );
                                })}
                                </div>
                            </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                    <div>
                        <br></br>
                        <PlacesAutocomplete
                            value={this.state.toAddress}
                            onChange={this.handleChange2}
                            onSelect={this.handleSelect2}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                <input name="toAddress" id="toAddress"
                                {...getInputProps({
                                    placeholder: 'Search Places ...',
                                    className: 'location-search-input',
                                })}
                                tabIndex="2"/>
                                <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map(suggestion => {
                                    const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                        className,
                                        style,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                    );
                                })}
                                </div>
                            </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                    <br></br>
                    <button ref="btn" type="submit" class="btn btn-primary contact_button">Send</button>
                    <br></br>
                </form>
          </div>
          
        );
      }
}

export default withRouter(Quote);








