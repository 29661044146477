import React, {Component} from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Landing from './components/pages/landingPage';
import Homepage from './components/pages/homePage';
import Footer from './components/footer/footer';

//CSS
import './css/default.css';

class App extends Component{
  render(){
    return(
      <Router>
        <div className="App">
          <Landing />
            <Route path='/' exact={true} component={Homepage} />
          <Footer />

        </div>
      </Router>
    );
  }
}
export default App;
