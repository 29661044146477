import React, { Component } from 'react';

var cur_year = new Date().getFullYear();
var footer_text = "AKAAL \u00A9 2010 - " + cur_year;

class Footer extends Component {
  render() {
    return (
        <footer>
          <div class="container">
            <br></br>
            <div class="row align-items-center">
                <div class="col-2"></div>
                <div class="col-8 text-center">
                {/*}
                  <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/HeberlingAgency/">
                    <i id="footer_icon" className="fa fa-facebook fa-2x fa-fw"></i>
                  </a>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/heberlingagency/">
                    <i id="footer_icon" className="fa fa-instagram fa-2x fa-fw"></i>
                  </a>
                */}
                  <br></br>
                  <p className="footer_name">
                    {footer_text}
                    <small>
                      <br></br>
                      Victorville &#8226; CA &#8226; 92392 
                      <br></br>
                      (844) 212-3477
                    </small>
                  </p>
                </div>
                <div class="col-2"></div>
            </div>
          </div>
        </footer>
    );
  }
}

export default Footer;