import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';


import Contact from './contactPage';
import Careers from './careerPage'; 
import Quote from './quotePage';
import Quote2 from './quote2Page';

import trucks_arial from '../images/trucks_arial.jpg';


class Homepage extends Component {
    render() {
        return (
            <div className="wrap">
                <nav className="navbar navbar-expand-sm bg-dark navbar-dark sticky-top">
                    <AnchorLink id="navbar_text" class="navbar-brand" href="#landingPage">AKAAL</AnchorLink>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                        <span class="navbar-toggler-icon"></span>
                    </button>

                    <div class="collapse navbar-collapse" id="collapsibleNavbar">
                        <ul id="home_nav" class="navbar-nav home_nav">
                            <li className="nav-item">
                                <AnchorLink className="nav-link" id="navbar_text" href="#quote_section">Instant Quote</AnchorLink>
                            </li>
                            <li className="nav-item">
                                <AnchorLink className="nav-link" id="navbar_text" href="#features_section">Services</AnchorLink>
                            </li>
                            <li className="nav-item">
                                <AnchorLink className="nav-link" id="navbar_text" href="#about_link">About Us</AnchorLink>
                            </li>
                            <li className="nav-item">
                                <AnchorLink className="nav-link" id="navbar_text" href="#careers_section">Careers</AnchorLink>
                            </li>
                            <li className="nav-item">
                                <AnchorLink className="nav-link" id="navbar_text" href="#contact_section">Contact</AnchorLink>
                            </li>
                            
                            {/*
                            <li className="nav-item">
                                <a className="nav-link" id="navbar_text" target="_blank" rel="noopener noreferrer" href="https://akaalcarriers.rmissecure.com/_s/reg/GeneralRequirementsV2.aspx">Become a Carrier</a>
                            </li>
                            */}
                        </ul>
                    </div>
                </nav>
                {/*Quote*/}
                <div id="quote_section" class="jumbotron text-center default_text_color">
                    <div class="row align-items-center">
                        <div class="col-md-4"></div>
                        <div class="col-md-4">
                            <h1 id="white_background_text">Instant Shipping Quote</h1>
                            <hr id="standard_line"></hr>
                        </div>
                        <div class="col-md-4"></div>
                    </div>

                    <br></br><br></br>
                    <div class="row align-items-center">
                        <div class="col-md-1"></div>
                        <div class="col-md-5">
                            <div class="shadow-lg p-4 mb-4 bg-white">
                                <i id="quote_icons" class="fa fa-handshake-o"></i>
                                <br></br><br></br>
                                <p id="homepage_icon_text" class="about_text">
                                    Welcome to Akaal, where we redefine reliability in transportation. As a small yet dedicated team of professionals, we specialize in delivering exceptional freight services tailored to meet our clients' needs. Established on the principles of integrity, efficiency, and safety, we pride ourselves on our commitment to timely deliveries and personalized customer care. With a fleet of modern vehicles and a passionate crew, we aim to exceed expectations, one mile at a time.
                                </p>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div id="careers_box" class="shadow-lg p-4 mb-4 bg-white">
                                <Quote2 />
                            </div>
                        
                        </div>
                        
                        <div class="col-md-1"></div>
                    </div>

                </div>
                
                <div id="features_section" class="jumbotron text-center">
                    <div class="row align-items-center">
                        <div class="col-md-4"></div>
                        <div class="col-md-4">
                            <h1 id="white_background_text">Services</h1>
                            <hr id="standard_line"></hr>
                        </div>
                        <div class="col-md-4"></div>
                    </div>
                

                    {/*Services Cards*/}
                    <br></br><br></br>
                    <div id="features_section">
                        <div class="card-deck features_row">
                            <div class="card features_card">
                                <div class="card-block features_block">
                                    <i id="features_icons" class="fa fa-thermometer-empty"></i>
                                    <h3 id="white_background_text" class="card-title features_title">Reefer</h3>
                                    <hr></hr>
                                    <div id="services_list">
                                        <h6 className="default_text_color"><i id="services_icons" class="fa fa-check"></i>Temperature Controlled</h6>
                                        <h6 className="default_text_color"><i id="services_icons" class="fa fa-check"></i>Produce</h6>
                                        <h6 className="default_text_color"><i id="services_icons" class="fa fa-check"></i>Meats</h6>
                                    </div>
                                </div>
                            </div>
                    
                            <div class="card features_card">
                                <div class="card-block features_block">
                                    <i id="features_icons" class="fa fa-truck"></i>
                                    <h3 id="white_background_text" class="card-title features_title">Dry Van</h3>
                                    <hr></hr>
                                    <div id="services_list">
                                        <h6 className="default_text_color"><i id="services_icons" class="fa fa-check"></i>General Freight</h6>
                                        <h6 className="default_text_color"><i id="services_icons" class="fa fa-check"></i>Mail</h6>
                                    </div>
                                </div>
                            </div>
                        
                            <div class="card features_card">
                             <div class="card-block features_block">
                                    <i id="features_icons" class="fa fa-clock-o"></i>
                                    <h3 id="white_background_text" class="card-title features_title">Expedited Delivery</h3>
                                    <hr></hr>
                                    <div id="services_list">
                                        <h6 className="default_text_color"><i id="services_icons" class="fa fa-check"></i>On Time</h6>
                                        <h6 className="default_text_color"><i id="services_icons" class="fa fa-check"></i>Error Free</h6>
                                    </div>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                </div>



                
                {/* ABOUT US */}
                <div id="about_link"></div>
                <br></br>
                <div id="about_section" class="container-fluid text-center">
                    <br></br>
                    <div class="row align-items-center">
                        <div class="col-md-4"></div>
                        <div class="col-md-4">
                            <h1 id="white_background_text">About Us</h1>
                            <hr id="standard_line"></hr>
                        </div>
                        <div class="col-md-4"></div>
                    </div>

                    <div class="row align-items-center">
                        <div class="col-md-1"></div>
                        <div class="col-md-6">
                            <img id="about_picture" className="img-fluid" src={trucks_arial}></img>
                        </div>
                        <div class="col-md-4">
                            <div class="shadow-lg p-4 mb-4 bg-white">
                                <h5 id="homepage_icon_text" >Our Mission</h5>
                                <p class="about_text" id="homepage_icon_text">
                                    At Akaal, we're not just about transporting goods from point A to point B; we're about fostering partnerships and delivering promises. As a locally-owned and operated small trucking company, we understand the importance of every shipment, whether it's across the state or across the country.
                                    <br></br><br></br>
                                    Our story began with a vision to revolutionize the trucking industry by combining traditional values with innovative solutions. With years of experience under our belt, we've honed our expertise in logistics, ensuring that each delivery is handled with precision and care.
                                    <br></br><br></br>
                                    What sets us apart is our unwavering dedication to customer satisfaction. We believe in building lasting relationships, offering a personalized approach to each client's unique needs. Our commitment to reliability, punctuality, and open communication forms the cornerstone of our operations.
                                    <br></br><br></br>
                                    Equipped with a diverse fleet of vehicles, maintained to the highest standards, and operated by a team of skilled drivers, we guarantee the safe and efficient transportation of your cargo. We pride ourselves on our ability to adapt to evolving industry demands, utilizing cutting-edge technology to streamline our processes while maintaining our focus on quality service.
                                    <br></br><br></br>
                                    At Akaal, we're not just moving freight; we're delivering peace of mind. Join us on this journey as we continue to exceed.
                                </p>
                                <br></br>

                                <h5 id="homepage_icon_text" >Our Service Makes the Difference</h5>
                                <p class="about_text" id="homepage_icon_text">
                                AKAAL has been serving customers in transportation for over a decade. We provide nationwide coverage
                                to move freight to or from any destination in the US. Get in touch today to get a free quote.
                                </p>
                                <br></br>
                                {/*
                                <h5 id="homepage_icon_text" >Why AKAAL?</h5>
                                <p class="about_text" id="homepage_icon_text">
                                The Mission of AKAAL is to continually provide superior customer service and satisfaction while
                                providing a positive work enviroment. With every load we haul, our goal is to provide unsurpassed
                                reliability and attention to detail. With AKAAL your freight is in good hands.
                                </p>
                                */}
                            </div>
                        </div>
                        <div class="col-md-1"></div>
                    </div>
                    <br></br><br></br>
                </div>

                {/*Careers */}
                 <Careers />

                {/*Contact US*/}
                <div id="contact_section" class="jumbotron text-center default_text_color">
                    <div class="row align-items-center">
                        <div class="col-md-4"></div>
                        <div class="col-md-4">
                            <h1 id="white_background_text">Contact</h1>
                            <hr id="standard_line"></hr>
                        </div>
                        <div class="col-md-4"></div>
                    </div>

                    <br></br><br></br>
                    <div class="row">
                        <div class="col-md-1"></div>
                        <div class="col-md-5">
                            <i id="contact_icons" class="fa fa-address-book"></i>
                            <br></br><br></br>
                            <h5 className="default_text_color" >AKAAL Trucking Company</h5>
                            <h7 className="default_text_color" >Victorville, California 92392</h7><br></br>
                            <h7 className="default_text_color" >Toll Free: <a href="tel:(844)212-3477">(844) 212-3477</a></h7><br></br>
                            <h7 className="default_text_color" >Local: <a href="tel:(442)219-0013">(442) 219-0013</a></h7><br></br>
                            <h7 className="default_text_color" ><a href="mailto:info@akaal.io">info@akaal.io</a></h7><br></br>
                            <h7 className="default_text_color" ><a href="mailto:operations@akaal.io">operations@akaal.io</a></h7><br></br>
                            <h7 className="default_text_color" ><a href="mailto:accounting@akaal.io">accounting@akaal.io</a></h7><br></br>

                        </div>
                        <div class="col-md-5">
                            <div id="careers_box" class="shadow-lg p-4 mb-4 bg-white">
                                <Contact />
                            </div>
                        
                        </div>
                        <div class="col-md-1"></div>
                    </div>

                </div>


            </div>
        );
    }
}

export default Homepage;