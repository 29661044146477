import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Logo from '../images/logo.png';

class Landing extends Component {
    constructor(props){
        super(props);

        this.state = {
            height:0
        }

        this.handeClick = this.handeClick.bind(this);
    }

    componentDidMount(){
        const height = this.refs.landingPage.clientHeight;
        this.setState({height});
    }

    handeClick(){
        window.scroll({top:this.state.height , left:0, behavior: "smooth"});
    }

    
    render() {
        return (
            <div>
                <div ref="landingPage" id="landingPage">
                    <div id="landingPage_text">
                        <img className="img-fluid" src={Logo}></img>
                        {/*}
                        <h1 id="landingPage_header">AKAAL</h1>
                        <h2 id="landingPage_subheader">Trucking Company</h2>*/}
                        <br></br>

                        <AnchorLink id="landingPage_button" class="btn btn-outline-primary" href="#quote_section">
                            Get Instant Quote
                        </AnchorLink>
                       
                        <a id="landingPage_button" href="tel:(844)212-3477" class="btn btn-outline-secondary">Call Now:(844) 212-3477</a>
                    
                        <br></br><br></br>
                        
                        <AnchorLink href="#home_nav" offset={() => 1}><i id="landingPage_icons" class="fa fa-angle-double-down"></i></AnchorLink>
                    </div>
                </div>
            </div>

        );
    }  
}

export default Landing;